import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Paper, Box, Button } from '@mui/material';
import { fetchQuestionsByQuestionSet } from '../../services/GetQuestionsService';
import { fetchQuestionSetById } from '../../services/GetQuestionSetService';
import './LearningMode.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ShuffleButton from '../../components/Button/ShuffleButton';
import {useTheme} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LearningMode = ({ open, onClose, questionSetId }) => {
  const [questions, setQuestions] = useState([]);
  const [questionSetName, setQuestionSetName] = useState('');
  const [currentIndex, setCurrentIndex] = useState(() => {
    const savedIndex = localStorage.getItem(`learningModeIndex-${questionSetId}`);
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  
  const [isFlipped, setIsFlipped] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const [displayContent, setDisplayContent] = useState('');
  const [contentType, setContentType] = useState('');
  const [isPoemVisible, setIsPoemVisible] = useState(false); 
  const [isShuffled, setIsShuffled] = useState(false);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [isWiggling, setIsWiggling] = useState(false);
  const currentQuestions = isShuffled ? shuffledQuestions : questions;
  const theme = useTheme();


  const shuffleQuestions = (questions) => {
    let shuffled = [...questions];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap
    }
    return shuffled;
  };

  const toggleShuffle = () => {
    setIsWiggling(true);  // Start the wiggle effect
    setTimeout(() => {
        setIsWiggling(false);  // Stop the wiggle effect after it completes
    }, 600);  // The duration should match the CSS animation duration

    if (!isShuffled) {
        const shuffled = shuffleQuestions(questions);
        setShuffledQuestions(shuffled);
        setCurrentIndex(0);  // Optionally reset to the first question on shuffle
        setIsShuffled(true);
    } else {
        setIsShuffled(false);
        setCurrentIndex(0);  // Optionally reset to the first question when unshuffled
    }
};

  
  useEffect(() => {
    if (!isShuffled) {
      setShuffledQuestions([]); // Clear shuffled questions when toggling off
    }
  }, [isShuffled]);

  useEffect(() => {
    console.log("Questions have been set or updated:", questions);
    console.log("Shuffled questions are now:", shuffledQuestions);
  }, [questions, shuffledQuestions]);
  
  useEffect(() => {
    console.log("Current Index updated to:", currentIndex);
  }, [currentIndex]);
  



useEffect(() => {
  const fetchData = async () => {
      try {
          const questionData = await fetchQuestionsByQuestionSet(questionSetId);
          setQuestions(questionData);
          console.log("Fetched Questions =", questionData);
          
          const questionSetData = await fetchQuestionSetById(questionSetId);
          setQuestionSetName(questionSetData.name);
      } catch (error) {
          console.error('Failed to fetch question set details:', error);
      }
  };

  if (open) {
      fetchData();
  }
}, [open, questionSetId]);


  const handlePaperClick = () => {
    setIsFlipped(!isFlipped);
    setDisplayContent('');  // Clear display content when flipping the paper
};

useEffect(() => {
  console.log("Current Index =", currentIndex);
  localStorage.setItem(`learningModeIndex-${questionSetId}`, currentIndex);
}, [currentIndex, questionSetId]);


const handleNextClick = () => {
  console.log("Next Click: Current Index =", currentIndex);
  if (currentIndex < currentQuestions.length - 1) {
    setCurrentIndex(prevIndex => prevIndex + 1);
  } else {
    setCurrentIndex(0); // Go back to the first card
    console.log("After Next Click: Reached end, going to first card");
  }
  setIsFlipped(false);
};

const handlePrevClick = () => {
  console.log("Prev Click: Current Index =", currentIndex);
  if (currentIndex > 0) {
    setCurrentIndex(prevIndex => prevIndex - 1);
  } else {
    setCurrentIndex(currentQuestions.length - 1); // Go back to the last card
    console.log("After Prev Click: Reached start, going to last card");
  }
  setIsFlipped(false);
};

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handlePrevClick();
    } else if (event.key === 'ArrowRight') {
      handleNextClick();
    }
  };

  if (open) {
    window.addEventListener('keydown', handleKeyDown);
  }

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, [open, handlePrevClick, handleNextClick]);



const handleQuoteClick = (e) => {
  e.stopPropagation();
  const quoteContent = questions[currentIndex].answer && questions[currentIndex].answer.quote ? questions[currentIndex].answer.quote.content : null;
  const fileName = questions[currentIndex].source_text_detail && questions[currentIndex].source_text_detail.file ? questions[currentIndex].source_text_detail.file.file_name : null;
  const pageNumber = questions[currentIndex].source_text_detail ? questions[currentIndex].source_text_detail.page_number : null;

  // Check if the quote content, file name, and page number are available
  if (quoteContent && fileName && pageNumber !== undefined) {
      const newDisplayContent = `${quoteContent}, (${fileName}, S. ${pageNumber})`;
      // Toggle display: clear if the same, update if different
      if (displayContent === newDisplayContent && contentType === 'quote') {
          setDisplayContent('');
          setContentType('');
      } else {
          setDisplayContent(newDisplayContent);
          setContentType('quote');
      }
  } else {
      // Set a default message if no quote details are available
      setDisplayContent("Entschuldige bitte, für diese Frage ist leider kein Zitat verfügbar.");
      setContentType('quote');
  }
};



const emojis = ["🌟", "📘", "🌈", "🍂", "🌼", "🌙", "✨", "🖋️", "📜"];

const handlePoemClick = (e) => {
  e.stopPropagation();  // Prevent event from bubbling up

  if (isPoemVisible) {
      setDisplayContent('');
      setIsPoemVisible(false);
      setContentType('');
  } else {
      const poemContent = questions[currentIndex].answer && questions[currentIndex].answer.memory_poem
          ? questions[currentIndex].answer.memory_poem.content 
          : '';

      if (poemContent) {
          const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
          const poemWithEmoji = poemContent + " " + randomEmoji;
          setDisplayContent(poemWithEmoji);
          setIsPoemVisible(true);
          setContentType('poem');
      } else {
          setDisplayContent("Für diese Frage ist kein Gedicht vorhanden.");
          setContentType('poem');
      }
  }
};



  return (
    <Dialog
  fullScreen
  open={open}
  onClose={onClose}
  TransitionComponent={Transition}
>
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',  
    alignItems: 'center',  
    height: '100vh',
    background: theme.palette.gradient.background
  }}>
    <Paper elevation={3} sx={{
      borderRadius: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.97)',
      boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 30px)', 
      width: 'calc(100vw - 20px)', 
    }}>
      {/* Non-scrolling Header */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 4,
        width: '100%',
        backgroundColor: 'transparent',
      }}>
        <Typography sx={{ flex: 1, color: "grey" }} variant="h6" component="div">
          {questionSetName || 'Loading...'}
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ width: 30, height: 30, padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    
  {/* Content Container for both Paper and Buttons */}
<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2, marginTop: 2}}>
<div className={`flip-container ${isWiggling ? 'wiggle' : ''}`} onClick={handlePaperClick}>
  <div className={`flipper ${isFlipped ? 'flipped' : ''}`}>
    <Paper className="front paper-content" sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h5" component="div" align="center" sx={{ fontSize: '1.8em' }}>
      {currentQuestions.length > 0 ? 
      currentQuestions[currentIndex].content.split(/\/n|\\n/).map((line, idx) => (
         <React.Fragment key={idx}>
         {line}
          <br />
        </React.Fragment>
        )) 
          : 'No question available'
        }
      </Typography>
    </Paper>
    <Paper className="back paper-content" sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="h5" component="div" align="center" sx={{ fontSize: '1.1em', mb: 2 }}>
        {currentQuestions.length > 0 ? 
        currentQuestions[currentIndex].answer.content.split(/\/n|\\n/).map((line, idx) => (
          <React.Fragment key={idx}>
          {line}
           <br />
         </React.Fragment>
         )) 
           : 'No answer available'
         }
       </Typography>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', mt: 2 }}>
        <Button color="secondary" variant="outlined" sx={{ mx: 1 }} onClick={(e) => handleQuoteClick(e)}>Quelle</Button>
        <Button color="secondary" variant="outlined" sx={{ mx: 1 }} onClick={(e) => handlePoemClick(e)}>Gedicht</Button>
      </Box>
      <Typography variant="subtitle1" component="div" align="center" sx={{
        mt: 4,
        fontFamily: contentType === 'poem' ? "'Dancing Script', cursive" : 'inherit',
        fontSize: contentType === 'poem' ? '1.2em' : 'inherit'
      }} className={contentType === 'poem' ? 'sparkly-text' : ''}>
        {displayContent}
      </Typography>
      </Paper>
      </div>
    </div>
    {/* Button Container directly below the paper */}
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 3 }}>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
    <IconButton
      color="secondary"
      onClick={handlePrevClick}
      sx={{
        mx: 8,
        width: 56,
        height: 56,
        borderRadius: '50%',
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: '#f5f5f5',
            borderColor: 'secondary.main',
        }
      }}
    >
      <KeyboardArrowLeft style={{ fontSize: '2rem' }} />
    </IconButton>

    {/* Current Question / Total Questions */}
    <Typography variant="subtitle1" sx={{ userSelect: 'none' }}>
      {`${currentIndex + 1}/${questions.length}`}
    </Typography>

    <IconButton
      color="secondary"
      onClick={handleNextClick}
      sx={{
        mx: 8,
        width: 56,
        height: 56,
        borderRadius: '50%',
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: '#f5f5f5',
            borderColor: 'secondary.main',
        }
      }}
    >
      <KeyboardArrowRight style={{ fontSize: '2rem' }} />
    </IconButton>
  </Box>
  
  {/* Shuffle Button */}
  <Box sx={{ mt: 2 }}>
    <ShuffleButton isShuffled={isShuffled} toggleShuffle={toggleShuffle} />
  </Box>
</Box>
  </Box>
  </Paper>
</Box>

    </Dialog>
  );
};

export default LearningMode;
