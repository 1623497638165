import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, Divider, TextField, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import StartLearningModeButton from './Button/StartLearningModeButton';
import StartAnsweringModeButton from './Button/StartAnsweringModeButton';
import { fetchQuestionsByQuestionSet } from '../services/GetQuestionsService';
import { editQuestion } from '../services/EditQuestionService';
import { createQuestion } from '../services/CreateQuestionService';
import { fetchQuestionSetById } from '../services/GetQuestionSetService';
import DeleteQuestionButton from './Button/DeleteQuestionButton';
import EditQuestionButton from './Button/EditQuestionButton';
import CreateQuestionButton from './Button/CreateQuestionButton';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from '@mui/material';

const QuestionSetDetail = ({ questionSet, onBack }) => {
  const [questions, setQuestions] = useState([]);
  const [editingQuestionId, setEditingQuestionId] = useState(null); // Track which question is being edited
  const [newQuestionContent, setNewQuestionContent] = useState(''); // Track new question content
  const [newAnswerContent, setNewAnswerContent] = useState(''); // Track new answer content
  const [isCreatingNewQuestion, setIsCreatingNewQuestion] = useState(false); // Track new question creation
  const [newQuestionIndex, setNewQuestionIndex] = useState(null); // Track where new question should be inserted
  const [errorMessage, setErrorMessage] = useState('');
  const [errorInfo, setErrorInfo] = useState({ message: '', type: '' });
  const [showPagesError, setShowPagesError] = useState(true);
  const theme = useTheme();





  useEffect(() => {
    if (questionSet?.id) {
      const pagesErrorDismissed = localStorage.getItem(`pagesErrorDismissed-${questionSet.id}`) === 'true';
      setShowPagesError(!pagesErrorDismissed);
  
      // Fetch questions related to the question set
      fetchQuestionsByQuestionSet(questionSet.id)
        .then(data => {
          const storedOrder = localStorage.getItem(`questionOrder-${questionSet.id}`);
          if (storedOrder) {
            const order = JSON.parse(storedOrder);
            const orderedQuestions = order.map(id => data.find(q => q.id === id)).filter(q => q);
            setQuestions(orderedQuestions);
          } else {
            setQuestions(data);
          }
        })
        .catch(error => console.error('Failed to fetch questions:', error));
  
      // Fetch the question set details
      fetchQuestionSetById(questionSet.id)
        .then(data => {
          if (data.questions_status === "failed") {
            setErrorInfo({ message: "Dieses Quiz konnte nicht erstellt werden. Dies könnte daran liegen, dass die Materialen, die du zur Erstellung des Quiz genutzt hast, nicht ausreichend Inhalt haben. Bitte überprüfe deine Auswahl und erstelle ein neues Quiz.", type: 'total' });
          } else if (data.questions_status === "partial" && !pagesErrorDismissed) {
            const errorDetails = JSON.parse(data.error_details);
            const failedPagesMessage = generateFailedPagesMessage(errorDetails.failed_pages);
            setErrorInfo({ message: failedPagesMessage, type: 'pages' });
          }
        })
        .catch(error => {
          console.error('Failed to fetch question set details:', error);
          setErrorInfo({ message: "Fehler beim Laden des Quizdetails.", type: 'total' });
        });
    }
  }, [questionSet]);
  
  

  // Function to generate error message for failed pages
  function generateFailedPagesMessage(failedPages) {
    if (failedPages.length > 0) {
      // Create a Set from failedPages to remove duplicates
      const uniquePages = new Set(failedPages);
      // Convert the Set back to an array and add one to each index
      const adjustedPages = Array.from(uniquePages).map(page => page + 1);
      // Join the adjusted page numbers into a string
      const pagesList = adjustedPages.join(", ");
      return `Bitte beachte: Für die Seite(n) ${pagesList} konnten keine Fragen erstellt werden. Entweder enthalten sie zu wenig Text oder bei der Fragestellung ist ein Fehler passiert.`;
    }
    return '';
  }
  

// Function to handle closing the error message for pages type
function handleClosePagesError() {
  localStorage.setItem(`pagesErrorDismissed-${questionSet.id}`, 'true');
  setShowPagesError(false);
}

const renderErrorInfo = () => {
  if (errorInfo.message && errorInfo.type === 'pages' && showPagesError) {
    return (
      <Box sx={{
        mt: 2,
        textAlign: 'center',
        fontSize: '1rem',
        p: 3,
        border: 1,
        borderColor: 'grey.500',
        borderRadius: '4px',
        backgroundColor: 'grey.100',
        color: 'grey.800',
        width: 'auto',
        mx: 'auto',
        maxWidth: '90%',
        position: 'relative'
      }}>
        <IconButton
        onClick={handleClosePagesError}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          m: 0.5, // margin to have a little space from the edges
          color: 'grey.600',
          height: 24, // smaller height
          width: 24,  // smaller width
          p: 0.5, // smaller padding
        }}
    >
          <CloseIcon />
        </IconButton>
        {errorInfo.message}
      </Box>
    );
  } else if (errorInfo.message && errorInfo.type === 'total') {
    return (
      <Typography
        sx={{
          mt: 2,
          textAlign: 'center',
          fontSize: '1.2rem',
          p: 2,
          border: 1,
          borderColor: 'error.main',
          borderRadius: '4px',
          color: 'error.main',
          width: 'auto',
          mx: 'auto',
          maxWidth: '90%',
        }}
      >
        {errorInfo.message}
      </Typography>
    );
  }
  return null;
};


  const saveOrderToLocalStorage = (newOrder) => {
    localStorage.setItem(`questionOrder-${questionSet.id}`, JSON.stringify(newOrder));
  };

  const handleEditClick = (question) => {
    const formattedQuestionContent = question.content.replace(/\/n|\\n/g, '\n');
    const formattedAnswerContent = question.answer.content.replace(/\/n|\\n/g, '\n');
    setEditingQuestionId(question.id);
    setNewQuestionContent(question.content);
    setNewAnswerContent(question.answer.content);
  };
  

  const handleSaveClick = async (questionId) => {
    try {
      await editQuestion(questionId, { content: newQuestionContent, answer: { content: newAnswerContent } });
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId ? { ...q, content: newQuestionContent, answer: { ...q.answer, content: newAnswerContent } } : q
        )
      );
      setEditingQuestionId(null);
    } catch (error) {
      console.error('Failed to save question:', error);
    }
  };

  const handleCancelClick = () => {
    setEditingQuestionId(null);
    setIsCreatingNewQuestion(false);
  };

  const handleQuestionDeleted = (deletedQuestionId) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = prevQuestions.filter((question) => question.id !== deletedQuestionId);
      saveOrderToLocalStorage(updatedQuestions.map(q => q.id));
      return updatedQuestions;
    });
  };

  const handleCreateNewQuestion = (index) => {
    setIsCreatingNewQuestion(true);
    setNewQuestionIndex(index);
    setNewQuestionContent(''); // Reset the content for new question
    setNewAnswerContent('');   // Reset the content for new answer
    setErrorMessage(''); // Clear any previous errors
};

const handleKeyDown = (event, currentValue, setter) => {
  if (event.key === 'Enter' && event.shiftKey) {
    event.preventDefault(); // Prevent default Enter key behavior
    setter(currentValue + '\n'); // Append a newline character at the current cursor position or at the end
  }
};



const handleSaveNewQuestionClick = async () => {
  const newQuestionData = {
    question_set: questionSet.id,
    question_type: "SA",
    content: newQuestionContent,
    answer: {
      content: newAnswerContent,
      is_correct: true,
    }
  };

  try {
    const createdQuestion = await createQuestion(newQuestionData);
    const updatedQuestions = [...questions];
    updatedQuestions.splice(newQuestionIndex, 0, createdQuestion);
    saveOrderToLocalStorage(updatedQuestions.map(q => q.id)); // Save the new order
    setQuestions(updatedQuestions);
    setIsCreatingNewQuestion(false);
    setNewQuestionContent('');
    setNewAnswerContent('');
    setErrorMessage(''); // Clear any previous errors
  } catch (error) {
    console.error('Failed to create new question:', error);
    if (error.response && error.response.data && error.response.data.non_field_errors) {
      if (error.response.data.non_field_errors.includes("The fields question_set, content must make a unique set.")) {
          setErrorMessage('This question has already been added.'); // Specific message for duplicate questions
      } else {
          setErrorMessage('Failed to save the question. Please try again.'); // Generic error message
      }
  } else {
      setErrorMessage('Die Frage kann nicht gespeichert werden. Bitte achte darauf, dass Fragen nicht doppelt einem Frageset hinzugefügt werden dürfen.'); // Default error message
  }
}
};

  return (
    <Box sx={{ p: 3, width: '100%', height: "100vh", position: 'relative', mb: 2 }}> 
    <Box sx={{
      position: 'relative', 
      textAlign: 'center', 
    }}>
    <IconButton onClick={onBack} sx={{
                    marginLeft: 'auto',
                    width: 30,
                    height: 30,
                    padding: 0,
                    position: 'absolute', top: 16, right: 16,
                    '&:hover': {
                        color: theme.palette.primary.main, // Color on hover
                        backgroundColor: 'transparent' // Optional: makes background transparent on hover
                    }
                }}>
                    <CloseIcon />
                </IconButton>
        <Typography variant="h4" sx={{
                color: '#000', 
                textAlign: 'center', 
                fontSize: '24px', 
                fontStyle: 'normal', 
                fontWeight: 700, 
                lineHeight: 'normal' 
              }}>
          {questionSet?.name}
        </Typography>
        </Box>
      
        <Box sx={{display: 'flex', justifyContent: 'space-around', mt: 4, }}>
      <Box sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center',
        minWidth: '260px', 
      }}>
        <StartLearningModeButton questionSetId={questionSet.id} sx={{
          width: '100%' }} />
        <Typography sx={{
          mt: 1.5, 
          width: '260px', 
          color: "#707070",
          fontSize: "14px", 
        }}>
          Fragen und Antworten ganz entspannt im Karteikarten-Stil lernen.
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center', 
        minWidth: '260px', 
        p: 0, 
        m: 0, 
      }}>
        <StartAnsweringModeButton questionSetId={questionSet.id} sx={{
          width: '100%'}} />
        <Typography sx={{
          mt: 1.5, 
          width: '260px', 
          color: "#707070", 
          fontSize: "14px", 
          p: 0, 
        }}>
          Wissen überprüfen, selber antworten und sofort Feedback bekommen.
        </Typography>
        </Box>
      </Box>
      {renderErrorInfo()}
      {/* Dynamic List of questions*/}
      <Box sx={{
      mt: 2,
      pb: 2,
      height: '60vh', // Set a fixed height or use a value that works with your layout
      overflowY: 'auto', // Enable vertical scrolling
      '&::-webkit-scrollbar': {
       width: '0px',  // Hide scrollbar for WebKit browsers
        },
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        }}>
        {questions.map((question, index) => (
          <React.Fragment key={question.id}>
            {index !== 0 && (
              <Box 
                sx={{
                  mb: 2,
                  textAlign: 'center',
                }}
              >
                <CreateQuestionButton
                  className="create-button"
                  onClick={() => handleCreateNewQuestion(index)}
                />
              </Box>
            )}
            {/* PAPER FOR CREATING A QUESTION BEGINS*/}
            {isCreatingNewQuestion && newQuestionIndex === index && (
              <Paper
                sx={{
                  mb: 4,
                  width: 'calc(100% - 48px)',
                  mx: 'auto',
                  p: 2,
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: "20px"
                }}
                elevation={4}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography sx={{ position: 'relative' }}>
                    {/* No index displayed */}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    backgroundColor: 'lightgrey',
                    height: 2,
                    width: 'calc(100% - 32px)',
                    position: 'absolute',
                    top: 50,
                    left: 16,
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, mt: 5 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ flex: 1, textAlign: 'left', pr: 2, position: 'relative' }}>
                      <TextField
                        value={newQuestionContent}
                        onChange={(e) => setNewQuestionContent(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, newQuestionContent, setNewQuestionContent)}
                        fullWidth
                        placeholder="Neue Frage eingeben"
                      />
                      <Divider sx={{ width: '100%', backgroundColor: 'black', mt: 1}} />
                      <Typography variant="body2" color="grey" sx={{ mt: 1 }}>
                        Frage
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'left', pl: 2, position: 'relative' }}>
                      <TextField
                        value={newAnswerContent}
                        onChange={(e) => setNewAnswerContent(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, newAnswerContent, setNewAnswerContent)}
                        fullWidth
                        placeholder="Neue Antwort eingeben"
                      />
                      <Divider sx={{ width: '100%', backgroundColor: 'black' , mt: 1}} />
                      <Typography variant="body2" color="grey" sx={{ mt: 1 }}>
                        Antwort
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'left'}}>
  {errorMessage && (
    <Typography 
      variant="caption" 
      color="error" 
      sx={{ marginRight: 2 }} // Add some margin to separate it from the buttons
    >
      {errorMessage}
    </Typography>
  )}
  <IconButton color="secondary" sx={{ mr: 1, width: 40, height: 40, padding: 0 }} onClick={handleSaveNewQuestionClick}>
    <CheckCircleIcon />
  </IconButton>
  <IconButton color="error" sx={{ mr: 1, width: 40, height: 40, padding: 0 }} onClick={handleCancelClick}>
    <CancelIcon />
  </IconButton>
</Box>

                </Box>
              </Paper>
            )}
            {/* PAPER FOR CREATING QUESTIONS ENDS*/}
            {/* PAPER FOR QUESTIONS DISPLAY BEGINS*/}
            <Paper
  sx={{
    width: 'calc(100% - 48px)',
    mx: 'auto',
    p: 2,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "20px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.20)"
  }}
  elevation={4}
>
  <Box sx={{ display: 'flex', flexDirection: 'column', p:"0"}}>
    {/* Top section for actions */}
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  marginLeft: 'auto', }}>
      <Box sx={{ display: 'flex', position: 'relative', gap: 1.5, mr:0.4 }}>
        <EditQuestionButton onClick={() => handleEditClick(question)} />
        <DeleteQuestionButton questionId={question.id} onQuestionDeleted={() => handleQuestionDeleted(question.id)} />
      </Box>
    </Box>
    {/* Content area */}
    <Box sx={{ flexGrow: 1, display: 'flex', marginTop:1 }}>
      {/* Question content or editable field */}
      <Box sx={{ flex: 1, 
    paddingRight: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',}}>
        {editingQuestionId === question.id ? (
          <TextField
          value={newQuestionContent}
          onChange={(e) => setNewQuestionContent(e.target.value)}
          fullWidth
          multiline
          variant="outlined"
          InputProps={{ style: { minHeight: 'auto', textAlign: 'left' } }}
          rows={Math.ceil(newQuestionContent.length / 60) || 1}
        />        
        ) : (
          <Typography sx={{fontSize: "16px", fontWeight: "300", lineHeight:"27px" }} align="left">
            {question.content.split(/\/n|\\n/).map((line, idx) => (
    <React.Fragment key={idx}>
      {line}
      <br />
    </React.Fragment>
  ))}
          </Typography>
          
        )}
      </Box>
      {/* Answer content or editable field */}
      <Box sx={{ flex: 1, 
    paddingLeft: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',  
    }}>
        {editingQuestionId === question.id ? (
          <TextField
            value={newAnswerContent}
            onChange={(e) => setNewAnswerContent(e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            InputProps={{ style: { minHeight: 'auto', textAlign: 'left' } }}
            rows={Math.ceil(newAnswerContent.length / 60) || 1} 
            
          />
        ) : (
          <Typography sx={{fontSize: "16px", fontWeight: "300", lineHeight:"27px" }} align="left">
  {question.answer.content.split(/\/n|\\n/).map((line, idx) => (
    <React.Fragment key={idx}>
      {line}
      <br />
    </React.Fragment>
  ))}
</Typography>

        )}
      </Box>
    </Box>
  </Box>

  {/* Fixed footer for labels and dividers */}
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Box sx={{ flex: 1, textAlign: 'left', pr: 2 }}>
      <Divider sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.20)', marginTop:1 }} />
      <Typography sx={{ mt: 0.5, color: "#A2A2A2", fontSize: "14px", fontWeight: 400  }}>Frage</Typography>
    </Box>
    <Box sx={{ flex: 1, textAlign: 'left', pl: 2 }}>
      <Divider sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.20)', marginTop:1}} />
      <Typography sx={{ mt: 0.5, color: "#A2A2A2", fontSize: "14px", fontWeight: 400 }}>Antwort</Typography>
    </Box>
  </Box>

  {/* Edit and cancel buttons */}
  {editingQuestionId === question.id && (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
      <IconButton color="secondary" sx={{ width: 40, height: 40, padding: 0 }} onClick={() => handleSaveClick(question.id)}>
        <CheckCircleIcon />
      </IconButton>
      <IconButton color="error" sx={{ width: 40, height: 40, padding: 0 }} onClick={handleCancelClick}>
        <CancelIcon />
      </IconButton>
    </Box>
  )}
</Paper>

            {/* PAPER FOR QUESTIONS DISPLAY ENDS*/}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default QuestionSetDetail;
