import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {useTheme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Paper, Box, Button } from '@mui/material';
import { fetchQuestionsByQuestionSet } from '../../services/GetQuestionsService';
import { fetchQuestionSetById } from '../../services/GetQuestionSetService';
import './AnsweringMode.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import ENDPOINTS from '../../utils/apiConfig';
import { refreshToken } from '../../utils/AuthenticationService';
import FullPointsAnswerGif from '../../assets/Full_Points_Answer.gif';
import ShuffleButton from '../../components/Button/ShuffleButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AnsweringMode = ({ open, onClose, questionSetId }) => {
  const [questions, setQuestions] = useState([]);
  const [questionSetName, setQuestionSetName] = useState('');
  const [currentIndex, setCurrentIndex] = useState(() => {
    const savedIndex = localStorage.getItem(`answeringModeIndex-${questionSetId}`);
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [isFlipped, setIsFlipped] = useState(false);
  const [displayContent, setDisplayContent] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState(null); 
  const [isChecking, setIsChecking] = useState(false);
  const [isShuffled, setIsShuffled] = useState(false);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [isWiggling, setIsWiggling] = useState(false);
  const theme = useTheme();
  const currentQuestions = isShuffled ? shuffledQuestions : questions;
  const [errorMessage, setErrorMessage] = useState('');



  const shuffleQuestions = (questions) => {
    let shuffled = [...questions];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap
    }
    return shuffled;
  };

  const toggleShuffle = () => {
    setIsWiggling(true);  // Start the wiggle effect
    setTimeout(() => {
        setIsWiggling(false);  // Stop the wiggle effect after it completes
    }, 600);  // The duration should match the CSS animation duration

    if (!isShuffled) {
        const shuffled = shuffleQuestions(questions);
        setShuffledQuestions(shuffled);
        setCurrentIndex(0);  // Optionally reset to the first question on shuffle
        setIsShuffled(true);
    } else {
        setIsShuffled(false);
        setCurrentIndex(0);  // Optionally reset to the first question when unshuffled
    }
};
  
  useEffect(() => {
    if (!isShuffled) {
      setShuffledQuestions([]); // Clear shuffled questions when toggling off
    }
  }, [isShuffled]);

  useEffect(() => {
    console.log("Questions have been set or updated:", questions);
    console.log("Shuffled questions are now:", shuffledQuestions);
  }, [questions, shuffledQuestions]);
  
  useEffect(() => {
    console.log("Current Index updated to:", currentIndex);
  }, [currentIndex]);
  

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionData = await fetchQuestionsByQuestionSet(questionSetId);
        setQuestions(questionData);

        const questionSetData = await fetchQuestionSetById(questionSetId);
        setQuestionSetName(questionSetData.name);
      } catch (error) {
        console.error('Failed to fetch question set details:', error);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open, questionSetId]);

  useEffect(() => {
    localStorage.setItem(`anssweringModeIndex-${questionSetId}`, currentIndex);
  }, [currentIndex, questionSetId]);
  



const handleNextClick = () => {
  if (currentIndex < currentQuestions.length - 1) {
    setCurrentIndex(currentIndex + 1);
  } else {
    setCurrentIndex(0); // Loop back to the first question
  }
  setIsFlipped(false);
  setUserAnswer('');    // Clear user answer
  setFeedback(null);    // Clear feedback
  setIsChecking(false); // Reset checking status
};

const handlePrevClick = () => {
  if (currentIndex > 0) {
    setCurrentIndex(currentIndex - 1);
  } else {
    setCurrentIndex(currentQuestions.length - 1); // Loop back to the last question
  }
  setIsFlipped(false);
  setUserAnswer('');    // Clear user answer
  setFeedback(null);    // Clear feedback
  setIsChecking(false); // Reset checking status
};

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handlePrevClick();
    } else if (event.key === 'ArrowRight') {
      handleNextClick();
    }
  };

  if (open) {
    window.addEventListener('keydown', handleKeyDown);
  }

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, [open, handlePrevClick, handleNextClick]);


const handleUserAnswerChange = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleSubmitAnswer = async () => {
    if (!questions[currentIndex] || !userAnswer.trim()) {
      setErrorMessage('Bitte gib eine Antwort ein.'); 
      return;
    }
    setErrorMessage('');
    setFeedback(null);
    setIsChecking(true); // Set checking state to true to show loading message
  
    const apiUrl = ENDPOINTS.CREATE_SINGLE_RESPONSE_ASSESSMENT;
    const requestBody = {
      question: currentQuestions[currentIndex].id, // Assuming `id` is the attribute for question ID
      user_answer: userAnswer
    };
  
    let accessToken = localStorage.getItem('accessToken'); // Retrieve the access token from localStorage
  
    try {
      let response = await makeAuthenticatedRequest(apiUrl, requestBody, accessToken);
  
      if (!response.ok) {
        if (response.status === 401) {
          // Access token might have expired, try refreshing it
          accessToken = await refreshToken(); // Refresh token using your utility
          localStorage.setItem('accessToken', accessToken); // Update the stored token
          response = await makeAuthenticatedRequest(apiUrl, requestBody, accessToken); // Retry the request with the new token
        }
        if (!response.ok) {
          const errorData = await response.json();
          console.error('API Error:', errorData);
          throw new Error(errorData.detail || 'Unknown API error');
        }
      }
  
      const result = await response.json();
      console.log("Assessment created:", result);
      fetchAssessmentFeedback(result.id);
    } catch (error) {
      console.error('Failed to create assessment:', error);
      setIsChecking(false); // Ensure to turn off the loading indicator on error
    }
  };
  

  const fetchAssessmentFeedback = async (assessmentId, retryCount = 0) => {
    if (!assessmentId) return;
    const maxRetries = 5; // Set a max number of retries to avoid infinite loops
    const retryInterval = 2000; // Retry every 2000 milliseconds (2 seconds)
  
    const apiUrl = ENDPOINTS.GET_SINGLE_RESPONSE_ASSESMENT(assessmentId);
    let accessToken = localStorage.getItem('accessToken'); // Retrieve the token
  
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          accessToken = await refreshToken(); // Refresh token using your utility
          localStorage.setItem('accessToken', accessToken); // Update the stored token
          return fetchAssessmentFeedback(assessmentId, retryCount); // Retry the request with the new token
        }
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Unknown API error');
      }
  
      const feedbackData = await response.json();
  
      if (feedbackData.response_status !== 'completed' && retryCount < maxRetries) {
        setTimeout(() => fetchAssessmentFeedback(assessmentId, retryCount + 1), retryInterval);
      } else {
        setIsChecking(false);
        console.log("Feedback received:", feedbackData);
        setFeedback(feedbackData); // Store the feedback data if completed or max retries reached
      }
    } catch (error) {
      console.error('Failed to fetch assessment feedback:', error);
      if (retryCount < maxRetries) {
        setTimeout(() => fetchAssessmentFeedback(assessmentId, retryCount + 1), retryInterval);
      }
    }
  };
  
  
  const makeAuthenticatedRequest = async (url, data, token) => {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  };
  
  const getColorStyles = (level) => {
    switch (level) {
      case "1":
        return { borderColor: '#FFA500', backgroundColor: '#FFEFD5' }; // Orange
      case "2":
        return { borderColor: '#FFD700', backgroundColor: '#FFF8DC' }; // Gold
      case "3":
        return { borderColor: '#ADFF2F', backgroundColor: '#F0FFF0' }; // Greenyellow
      case "4":
        return { borderColor: '#7CFC00', backgroundColor: '#F5FFFA' }; // Lawngreen
      case "5":
        return { borderColor: '#00FF00', backgroundColor: '#F0FFF0' }; // Limegreen
      default:
        return { borderColor: '#D3D3D3', backgroundColor: '#F5F5F5' }; // Lightgrey for undefined levels
    }
  };
  
  
  

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',  
    alignItems: 'center',  
    height: '100vh',
    background: theme.palette.gradient.background
  }}>
    <Paper elevation={3} sx={{
      borderRadius: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.97)',
      boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 30px)', 
      width: 'calc(100vw - 20px)', 
    }}>
      {/* Non-scrolling Header */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 4,
        width: '100%',
        backgroundColor: 'transparent',
      }}>
        <Typography sx={{ flex: 1, color: "grey" }} variant="h6" component="div">
          {questionSetName || 'Loading...'}
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ width: 30, height: 30, padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
  {/* Content Container for both Paper and Buttons */}
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2, marginTop: 2 }}>
  <div className={`flip-container ${isWiggling ? 'wiggle' : ''}`}>
      <div className={`flipper ${isFlipped ? 'flipped' : ''}`}>
      <Paper className="paper-content" sx={{
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minHeight: '500px',  // Set this to whatever your current minimum height is or should be
  overflow: 'auto'  // Adjust this as needed to 'auto' if you want scrollbars when content overflows
}}>
  <Typography variant="h4" component="div" sx={{ fontSize: '1.3em', width: '100%', textAlign: 'left', mt: 1 }}>
    {currentQuestions.length > 0 ? 
      currentQuestions[currentIndex].content.split(/\/n|\\n/).map((line, idx) => (
        <React.Fragment key={idx}>
          {line}
          <br />
        </React.Fragment>
      )) 
      : 'No question available'
    }
  </Typography>
  <TextField
    fullWidth
    variant="outlined"
    color="secondary"
    multiline
    rows={3}
    placeholder="Gib hier deine Antwort ein"
    value={userAnswer}
    onChange={handleUserAnswerChange}
    sx={{ mt: 2 }}
  />
  <Button
    variant="contained"
    color="secondary"
    onClick={handleSubmitAnswer}
    disabled={isChecking} 
    sx={{ mt: 2, mb: 1, mx: "auto", display: 'block'}}
  >
    Antwort überprüfen
  </Button>
  {errorMessage && (
         <Typography
         variant="subtitle2"
         sx={{
           marginTop: 2,
           color: theme.palette.primary.main,
           textAlign: 'center',
           alignSelf: 'center', 
         }}
       >
         {errorMessage}
       </Typography>
        )}
  {isChecking && (
    <Typography variant="subtitle1" sx={{ mt: 2, color: 'gray' }}>
      Deine Antwort wird gerade überprüft...
    </Typography>
  )}
  {feedback && (
    <Box sx={{ mt: 2 }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        mb: 1,
      }}>
        <Typography variant="subtitle2" color="black" sx={{ textAlign: 'center', zIndex: 1 }}>
          {feedback ? `${feedback.correctness_level}/5 Punkte` : 'No feedback'}
        </Typography>
        {feedback && feedback.correctness_level === "5" && (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
            <img src={FullPointsAnswerGif} alt="Celebration" style={{ width: '150px', height: 'auto' }} />
          </Box>
        )}
      </Box>
      <Box sx={{
        border: 1,
        ...getColorStyles(feedback.correctness_level),
        p: 1,
        borderRadius: '8px',
        mb: 2
      }}>
        <Typography variant="body2" color="black" sx={{ fontWeight: 'bold' }}>
          Feedback:
        </Typography>
        <Typography variant="body2" color="black" sx={{ mb: 1 }}>
          {feedback ? feedback.llm_feedback : 'No feedback yet'}
        </Typography>
      </Box>
      <Box sx={{ border: 1, borderColor: "grey", p: 1, borderRadius: '8px', mb: 2 }}>
        <Typography variant="body2" color="black" sx={{fontWeight: 'bold'}}>
          Ideale Antwort:
        </Typography>
        <Typography variant="body2" color="black">
          {feedback.ideal_answer_content.split(/\/n|\\n/).map((line, idx) => (
            <React.Fragment key={idx}>
              {line}
              {idx < feedback.ideal_answer_content.split(/\/n|\\n/).length - 1 && <br />}
            </React.Fragment>
          ))}
        </Typography>
      </Box>
    </Box>
  )}
</Paper>

      </div>
    </div>
    {/* Button Container directly below the paper */}
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 3 }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
  <IconButton
    color="secondary"
    onClick={handlePrevClick}
    sx={{
      mx: 8,
      width: 56,
      height: 56,
      borderRadius: '50%',
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      backgroundColor: 'white',
      '&:hover': {
          backgroundColor: '#f5f5f5',
          borderColor: 'secondary.main',
      }
    }}
  >
    <KeyboardArrowLeft style={{ fontSize: '2rem' }} />
  </IconButton>

  {/* Current Question / Total Questions */}
  <Typography variant="subtitle1" sx={{ userSelect: 'none' }}>
    {`${currentIndex + 1}/${questions.length}`}
  </Typography>

  <IconButton
    color="secondary"
    onClick={handleNextClick}
    sx={{
      mx: 8,
      width: 56,
      height: 56,
      borderRadius: '50%',
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      backgroundColor: 'white',
      '&:hover': {
          backgroundColor: '#f5f5f5',
          borderColor: 'secondary.main',
      }
    }}
  >
    <KeyboardArrowRight style={{ fontSize: '2rem' }} />
  </IconButton>
  </Box>
  <Box sx={{ mt: 2 }}>
    <ShuffleButton isShuffled={isShuffled} toggleShuffle={toggleShuffle} />
  </Box>
</Box>
  </Box>
</Paper>
</Box>
    </Dialog>
  );
};

export default AnsweringMode;
